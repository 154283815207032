@use "./index.scss" as index;

.about-banner-container {
  position: relative;
  height: 35rem;
  overflow: hidden;

  @include index.breakpoints(992px) {
    overflow-x: hidden;
    height: 25rem;
  }
}

.about-page-container {
  padding: 0px 0rem;
}

.about-banner-img:nth-child(0) {
  @include index.breakpoints(992px) {
    // display: none;
  }
}

.about-banner-img {
  position: absolute;
  height: 100%;
  object-fit: cover;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.about-banner-body {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &.left {
    align-items: flex-start;
    text-align: left;
    padding-left: 5rem;
    padding-right: 60%;

    @include index.breakpoints(992px) {
      padding: 0 2.5rem;
    }
  }

  &.right {
    align-items: flex-end;
    text-align: right;
    padding-right: 5rem;
    padding-left: 60%;

    @include index.breakpoints(992px) {
      padding: 0 2.5rem;
    }
  }

  .about-banner-title {
    font-size: 2rem;
    margin: 0;

    @include index.breakpoints(992px) {
      font-size: 1rem;
    }
  }

  .about-banner-text {
    font-size: 1rem;
    margin: 0;

    @include index.breakpoints(992px) {
      font-size: 0.68rem;
    }
  }
}

.about-btn-learn-more {
  font-size: 0.85rem;
  cursor: pointer;
  margin-top: 0.5rem;
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 0.85rem 2.5rem;
  font-weight: bold;
  background-color: #a3d183;

  @include index.breakpoints(992px) {
    font-size: 0.65rem;
    padding: 0.45rem 1.25rem;
  }
}

.procedure-card-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.procedure-img {
  object-fit: unset;
  width: 5rem;
}

.procedure-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.procedure-title {
  text-align: center;

  @include index.breakpoints(992px) {
    font-size: 1.15rem;
  }
}

.procedure-arrow {
  margin-top: 2.5rem;
  height: 0.75rem;

  @include index.breakpoints(992px) {
    &.left {
      transform: scaleX(-1);
    }

    &.right {
      margin-left: -0.5rem;
    }

    &.down {
      transform: rotate(90deg);
    }
  }
}

.procedure-title {
  text-align: center;
}

.mobile-procedure-card-container {
  display: flex;
  flex-direction: column;
}

.mobile-procedure-card-top {
  display: flex;
  justify-content: center;
  .mobile-producer-card {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    .mobile-producer-image {
      width: 4.5rem;
    }
    p {
      text-align: center;
      font-size: 0.85rem;
    }
  }
}

.mobile-procedure-card-bottom {
  display: flex;
  justify-content: center;
  .mobile-producer-card {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    .mobile-producer-image {
      width: 4.5rem;
    }
    p {
      text-align: center;
      font-size: 0.85rem;
    }
  }
}

.procedure-arrow.down {
  margin-top: 0.5rem;
}
