@mixin breakpoints($size) {
  @media (max-width: #{$size}) {
    @content;
  }
}

@font-face {
  font-family: "poppins";
  font-weight: normal;
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";
  font-weight: lighter;
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";
  font-weight: 200;
  src: url("./fonts/Poppins-ExtraLight.ttf") format("truetype");
}


body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}
