@use "./index.scss" as index;

.banner-video {
  width: 100%;
  height: 95vh;
  overflow: hidden;
  position: relative;

  video {
    @include index.breakpoints(992px) {
      margin-top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.overlay-video {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 95vh;
  background-color: rgba(0, 0, 0, 0.65);
}

.banner-title {
  position: absolute;
  font-weight: bold;
  color: white;
  top: 20%;
  left: 7.5rem;

  @include index.breakpoints(992px) {
    left: 0;
    width: 100%;
    top: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-banner-title {
    font-size: 3rem;
    margin-bottom: 0.25rem;
    @include index.breakpoints(992px) {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 0.25rem;
    }
  }
}

.btn-banner {
  background-color: #caa4cc;
  border: none;
  font-size: 1rem;
  border-radius: 2.5rem;
  padding: 0.75rem 1.05rem;
  font-weight: bold;
  color: white;
  cursor: pointer;

  @include index.breakpoints(992px) {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
}

.home-ahli {
  margin-top: 5rem;
  padding-left: 5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.home-ahli-left {
  flex: 1;
  .home-ahli-left-title {
    font-size: 3rem;
    margin-bottom: 0.5rem;

    @include index.breakpoints(992px) {
      font-size: 1rem;
    }
  }
  .btn-home-ahli-left {
    background-color: #caa4cc;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.75rem 3rem;
    border: none;
    border-radius: 5rem;
  }
}

.btn-ahli-arrow {
  background-color: #caa4cc;
  border-radius: 50%;
  border: none;
  padding: 0.5rem 0.65rem 0.25rem 0.65rem;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1.25rem;
  }
}

.home-ahli-right {
  flex: 3;
  display: flex;
  overflow: hidden;
  position: relative;
}

.home-ahli-desc {
  color: white;
  text-align: center;
  width: 20rem;
  height: 6.5rem;
  padding: 1.5rem 0.5rem;
  background-color: #a3d183;
  margin-right: 2.5rem;

  h1 {
    font-size: 1rem;
    margin: 0;
  }
  p {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
}

.help-container {
  display: flex;
  padding: 0 10rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  @include index.breakpoints(992px) {
    gap: 1rem;
    padding: 0;
  }
}

.help-title-section {
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 3.5rem;

  @include index.breakpoints(992px) {
    font-size: 1.35rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.btn-learn-more-service-help {
  cursor: pointer;
  margin-top: 5rem;
  text-align: center;
  background-color: #caa4cc;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;

  @include index.breakpoints(992px) {
    font-size: 1rem;
    margin-top: 1.25rem;
    padding: 0.5rem 1.25rem;
  }
}

.help-card-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 15rem;
  height: 15rem;
  margin-bottom: 1.25rem;

  .help-card-background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h1 {
    text-align: center;
    font-size: 1.35rem;
  }

  @include index.breakpoints(992px) {
    width: 10rem;
    height: 10rem;
    margin-bottom: 0;

    h1 {
      font-size: 1rem;
    }
  }
}

.home-ahli-pic-container {
  position: relative;
  width: 20rem;
  height: 20rem;
}

.home-ahli-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.home-ahli-pic {
  height: 80%;
  left: 10%;
  position: absolute;
  bottom: 0;
}

.home-testimonial {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30rem;
  margin-top: 2.5rem;

  .home-testimonial-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    position: absolute;
  }

  .btn-next-testimony {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    margin-right: 1.5rem;
    @include index.breakpoints(992px) {
      height: 1rem;
      width: 1rem;
    }
  }
}

.home-testimonial-body {
  width: 60%;
  padding-right: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-info {
  margin-top: 2rem;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.home-info-container {
  display: flex;
  color: white;
  max-width: 65rem;
  background-color: #aa7fc9;
  padding: 2.5rem 1rem;

  .home-header-title {
    display: flex;
    align-items: center;

    h1 {
      margin: 0;
      font-size: 1.5rem;
      margin-left: 0.75rem;
      font-weight: normal;
    }
  }

  .home-call-us {
    padding-top: 2rem;
    padding-left: 3rem;
    border-right: 2px solid white;
    flex: 1;

    p {
      margin-top: 2rem;
    }

    .home-call-cta {
      display: flex;
      flex-direction: column;

      a {
        position: relative;
        z-index: 1;
        width: 10rem;
        font-size: 0.75rem;
        background-color: white;
        padding: 0.5rem;
        border-radius: 2rem;
        margin-bottom: 1rem;
        font-weight: bold;
        color: black;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  .home-location {
    padding: 1.85rem 3rem;
    border-right: 2px solid white;
    flex: 1;

    p {
      line-height: 28px;
    }

    .home-btn-vision-us {
      position: relative;
      z-index: 1;
      font-size: 0.75rem;
      background-color: white;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      margin-bottom: 1rem;
      font-weight: bold;
      color: black;
      text-decoration: none;
      text-align: center;
    }
  }

  .home-opening-hours {
    padding: 1.9rem 3rem;
    flex: 1;

    .home-opening-hours-section {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid white;
    }
  }
}

.home-newsletter {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -10rem;
  height: 35rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fdf6eb;
    z-index: -1;
  }

  h1 {
    margin-top: 6rem;
    text-align: center;
    width: 55%;
    margin-bottom: 0.25rem;
  }

  img {
    z-index: -1;
    position: absolute;
    top: 0;
  }

  #email {
    z-index: 1;
    text-align: center;
    background-color: transparent;
    border: 1px solid #272727;
    width: 25rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
  }

  .home-btn-subscribe {
    color: white;
    padding: 0.5rem 5rem;
    font-weight: bold;
    border: none;
    margin-top: 0.5rem;
    background-color: #a3d183;
    border-radius: 5rem;
    cursor: pointer;
  }
}

.mobile-home-ahli {
  margin-top: 1.5rem;
  position: relative;
  padding: 1rem 2.5rem;
  background-color: #fdf6eb;
  overflow: hidden;
}

.mobile-home-ahli-title {
  font-size: 1.25rem;
  text-align: center;
}

.mobile-card-container {
  width: max-content;
  display: flex;
  gap: 1.25rem;

  .mobile-card-button.left {
    left: 1rem;
  }

  .mobile-card-button.right {
    right: 0.25rem;
  }

  .mobile-card-button {
    background-color: #caa4cc;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    z-index: 1;
    position: absolute;
    border: none;
    top: 50%;

    img {
      margin-top: 0.35rem;
      width: 0.5rem;
      height: 0.75rem;
    }
  }
}

.mobile-card-container-card {
  width: 15.5rem;
  height: 20rem;
  background-color: #a3d183;

  .mobile-card-container-desc {
    padding: 0;
    box-sizing: border-box;
    h1 {
      font-size: 0.8rem;
      color: white;
      text-align: center;
    }

    p {
      font-size: 0.8rem;
      text-align: center;
      color: white;
      margin-top: 0;
    }
  }

  .mobile-card-container-bg-wrapper {
    height: 60%;
    position: relative;

    .mobile-card-container-background {
      width: 100.25%;
      height: 100.25%;
    }

    .mobile-card-container-image {
      position: absolute;
      bottom: 0;
      left: 2.25rem;
      width: 70%;
    }
  }
}
