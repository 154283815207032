@use "./index.scss" as index;

.navbar-icon {
  height: 2.25rem;

  @include index.breakpoints(860px) {
  }
}

.navbar-container-list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;

  @include index.breakpoints(860px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem .75rem;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    left: 0;
    width: 100%;
    position: absolute;
    top: 3.5rem;
    text-align: end;
    border-bottom: 1px solid lightgrey;
  }
}

.navbar-list {
  font-weight: bold;
  list-style: none;
  font-size: 0.75rem;
  margin: 0px 0.75rem;
}

.navbar {
  padding: 0.75rem 5rem;
  display: flex;
  align-items: center;

  @include index.breakpoints(860px) {
    z-index: 2;
    background-color: white;
    position: sticky;
    top: 0;
    padding: 0.5rem 1.25rem;
    justify-content: space-between;
  }
}

.m-hamburger-menu {
  display: none;
  @include index.breakpoints(860px) {
    display: block;
    img {
      cursor: pointer;
      height: 1.25rem;
      width: 1.75rem;
    }
  }
}

.navbar-link {
  text-decoration: none;
  color: black;
}

.btn-language img {
  margin-left: 0.25rem;
  height: 1rem;
  cursor: pointer;
}
