@font-face {
  font-family: "poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.services-banner-container {
  position: relative;
  width: 100%;
}

.services-banner {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  margin-bottom: 2.5rem;
}
@media (max-width: 992px) {
  .services-banner {
    display: none;
  }
}

.services-title {
  position: absolute;
  top: 30%;
  left: 45%;
  color: white;
}
@media (max-width: 992px) {
  .services-title {
    text-align: center;
    position: initial;
    color: black;
    font-size: 1.35rem;
  }
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;
  gap: 1.5rem;
}
@media (max-width: 992px) {
  .services-container {
    gap: 0;
    padding: 0 1rem;
  }
}

.services-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  width: 80%;
}
@media (max-width: 992px) {
  .services-card {
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem;
    border-radius: 1.25rem;
    height: 12.5rem;
  }
}

.services-card-img {
  height: 101.85%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  margin-right: -0.5rem;
  border-radius: 0.35rem;
  margin-top: -0.1rem;
}
@media (max-width: 992px) {
  .services-card-img {
    width: 48%;
  }
}

.services-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 0.5rem;
  border: 3px solid #caa4cc;
}
@media (max-width: 992px) {
  .services-body {
    padding: 0 1rem;
    height: 97.5%;
  }
}

.services-card-title {
  margin: 0;
}
@media (max-width: 992px) {
  .services-card-title {
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .services-card-desc {
    font-size: 5px;
    margin-top: 0.15rem;
    margin-bottom: 0;
  }
}/*# sourceMappingURL=Services.css.map */