@use "./index.scss" as index;

.services-banner-container {
  position: relative;
  width: 100%;
}

.services-banner {
  object-fit: cover;
  width: 100%;
  margin-bottom: 2.5rem;

  @include index.breakpoints(992px) {
    display: none;
  }
}

.services-title {
  position: absolute;
  top: 30%;
  left: 45%;
  color: white;

  @include index.breakpoints(992px) {
    text-align: center;
    position: initial;
    color: black;
    font-size: 1.35rem;
  }
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;
  gap: 1.5rem;
  @include index.breakpoints(992px) {
    gap: 0;
    padding: 0 1rem;
  }
}

.services-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  width: 80%;

  @include index.breakpoints(992px) {
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem;
    border-radius: 1.25rem;
    height: 12.5rem;
  }
}

.services-card-img {
  height: 101.85%;
  object-fit: cover;
  z-index: 1;
  margin-right: -0.5rem;
  border-radius: 0.35rem;
  margin-top: -0.1rem;
  @include index.breakpoints(992px) {
    width: 48%;
  }
}

.services-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 0.5rem;
  border: 3px solid #caa4cc;

  @include index.breakpoints(992px) {
    padding: 0 1rem;
    height: 97.5%;

  }
}

.services-card-title {
  margin: 0;
  @include index.breakpoints(992px) {
    font-size: 0.6rem;
    margin: 0;
  }
}

.services-card-desc {
  @include index.breakpoints(992px) {
    font-size: 5px;
    margin-top: 0.15rem;
    margin-bottom: 0;
  }
}
