@font-face {
  font-family: "poppins";
  font-weight: normal;
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: lighter;
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: 200;
  src: url("./fonts/Poppins-ExtraLight.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.contact-banner-info-container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 13.5rem;
  padding-top: 5rem;
  display: flex;
  gap: 3.5rem;
}
@media (max-width: 992px) {
  .contact-banner-info-container {
    flex-direction: column;
    padding: 0 3.5rem;
    padding-top: 3rem;
    gap: 2rem;
  }
}
.contact-banner-info-container .contact-banner-info-icon {
  font-size: 3.5rem;
}
@media (max-width: 992px) {
  .contact-banner-info-container .contact-banner-info-icon {
    font-size: 2rem;
  }
}
.contact-banner-info-container .contact-banner-info-card-title {
  margin: 0;
  font-size: 1.5rem;
}
@media (max-width: 992px) {
  .contact-banner-info-container .contact-banner-info-card-title {
    font-size: 1rem;
  }
}
.contact-banner-info-container .contact-banner-info-card-desc {
  text-align: center;
  margin: 0;
  font-size: 1rem;
  font-weight: 200;
}
@media (max-width: 992px) {
  .contact-banner-info-container .contact-banner-info-card-desc {
    font-size: 0.75rem;
  }
}
.contact-banner-info-container .contact-banner-info-card-notes {
  text-align: center;
  margin: 0;
  font-size: 1rem;
}
@media (max-width: 992px) {
  .contact-banner-info-container .contact-banner-info-card-notes {
    font-size: 0.75rem;
  }
}
.contact-banner-info-container .contact-banner-info-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
@media (max-width: 992px) {
  .contact-banner-info-container .contact-banner-info-card {
    gap: 0.35rem;
  }
}

.contact-banner-container {
  position: relative;
  width: 100%;
  height: 30rem;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
}
.contact-banner-container h1 {
  font-size: 4rem;
  margin: 0;
}
@media (max-width: 992px) {
  .contact-banner-container h1 {
    font-size: 2rem;
  }
}
.contact-banner-container p {
  margin: 0;
  margin-top: -1rem;
  font-size: 2.05rem;
}
@media (max-width: 992px) {
  .contact-banner-container p {
    margin-top: -0.5rem;
    font-size: 1.05rem;
  }
}

.contact-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.contact-info-email-container {
  padding: 0 6.5rem;
  padding-top: 5rem;
  display: flex;
  background-color: #EEEEEE;
  margin-top: 5rem;
  height: 80vh;
}
@media (max-width: 992px) {
  .contact-info-email-container {
    padding: 0;
    flex-direction: column;
    height: 100%;
    padding-bottom: 5rem;
  }
}
.contact-info-email-container .contact-info-email-container-left {
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2.5;
  border-right: 2px solid #D9D9D9;
}
.contact-info-email-container .contact-info-email-container-right {
  flex: 1;
  padding: 0 1.5rem;
  padding-top: 2.5rem;
}
@media (max-width: 992px) {
  .contact-info-email-container .contact-info-email-container-right {
    padding: 0 2.5rem;
    padding-top: 4.5rem;
  }
}

.contact-info-form-title {
  text-align: center;
}
.contact-info-form-title h1 {
  font-size: 2rem;
}
@media (max-width: 992px) {
  .contact-info-form-title h1 {
    font-size: 1.5rem;
  }
}

.contact-info-working-hours {
  display: flex;
  gap: 1.25rem;
}
.contact-info-working-hours .contact-info-working-hours-sections, .contact-info-working-hours .contact-info-working-day-sections {
  color: #818080;
}

.contact-info-email-container-right-desc {
  margin: 1rem 0;
}
@media (max-width: 992px) {
  .contact-info-email-container-right-desc {
    margin-top: 0.35rem;
  }
}

.contact-info-form-container {
  margin-top: 1.5rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  padding: 0 5rem;
}
@media (max-width: 992px) {
  .contact-info-form-container {
    padding: 0 2.5rem;
  }
}
.contact-info-form-container div {
  display: flex;
  gap: 1rem;
}
@media (max-width: 992px) {
  .contact-info-form-container div {
    flex-direction: column;
  }
}
.contact-info-form-container .btn-submit-form {
  background-color: #717171;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  border: none;
}
.contact-info-form-container input {
  padding: 0.65rem;
  border: none;
}
.contact-info-form-container textarea {
  border: none;
  resize: none;
  height: 6.5rem;
  padding: 0.65rem;
}
.contact-info-form-container input::-moz-placeholder, .contact-info-form-container textarea::-moz-placeholder {
  color: black;
}
.contact-info-form-container input:-ms-input-placeholder, .contact-info-form-container textarea:-ms-input-placeholder {
  color: black;
}
.contact-info-form-container input::placeholder, .contact-info-form-container textarea::placeholder {
  color: black;
}/*# sourceMappingURL=contact.css.map */