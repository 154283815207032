@font-face {
  font-family: "poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.home-social-info {
  padding-top: 1.35rem;
}
@media (max-width: 992px) {
  .home-social-info {
    display: none;
  }
}

.home-social-info-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.home-social-info-item {
  display: flex;
  align-items: center;
}

.home-social-info-link {
  text-decoration: none;
  color: black;
}

.icon-social-media {
  margin-right: 0.75rem;
  color: white;
  font-size: 1.5rem !important;
  padding: 0.5rem;
  border-radius: 5rem;
  background-color: black;
}

.home-social-media {
  padding: 0rem 5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
}
@media (max-width: 992px) {
  .home-social-media {
    padding: 0rem 1rem;
  }
}

.instagram-widget {
  width: 650px !important;
  position: relative;
}

.remove-watermark {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background-color: white;
  height: 2.25rem;
}/*# sourceMappingURL=Instagram.css.map */