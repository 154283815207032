.mobile-info-email-container {
  margin: 1.25rem 0;
  padding: 0 1.5rem;
  align-items: center;
  gap: 1rem;
}
.mobile-info-email-container .mobile-info-email-top {
  color: white;
  background-color: #AA7FC9;
}
.mobile-info-email-container .mobile-info-email-top .mobile-call-us {
  border-bottom: 1px solid white;
  padding: 0.75rem 1rem;
}
.mobile-info-email-container .mobile-info-email-top .mobile-call-us button {
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
  background-color: white;
  border: none;
  border-radius: 1rem;
  color: black;
  font-weight: bold;
  width: 9.5rem;
  padding: 0.35rem 0.85rem;
}
.mobile-info-email-container .mobile-info-email-top .mobile-info-location {
  padding: 1.25rem 1rem;
  border-bottom: 1px solid white;
}
.mobile-info-email-container .mobile-info-email-top .mobile-info-location button {
  font-size: 0.6rem;
  background-color: white;
  border: none;
  border-radius: 1rem;
  color: black;
  font-weight: bold;
  padding: 0.25rem 1rem;
}
.mobile-info-email-container .mobile-info-email-top .mobile-opening-hours {
  padding: 1.25rem 1rem;
  padding-bottom: 2rem;
}
.mobile-info-email-container .mobile-info-email-top .mobile-opening-hours .mobile-opening-hours-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
}
.mobile-info-email-container .mobile-info-email-top .mobile-title-section {
  margin-left: 0.5rem;
  font-weight: normal;
  font-size: 0.85rem;
}
.mobile-info-email-container .mobile-info-email-top p {
  font-size: 0.85rem;
}
.mobile-info-email-container .mobile-info-email-bottom {
  text-align: center;
}
.mobile-info-email-container .mobile-info-email-bottom .mobile-title-section {
  font-size: 1.5rem;
}

.mobile-icon-social-media {
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.25rem !important;
  color: white;
  background-color: black;
  border-radius: 2rem;
}

.mobile-email-container {
  margin-bottom: 1rem;
  position: relative;
}
.mobile-email-container img {
  width: 100%;
}
.mobile-email-container .mobile-email-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.mobile-email-container .mobile-email-body h1 {
  text-align: center;
  font-size: 1rem;
}
.mobile-email-container .mobile-email-body .email {
  padding: 0.3rem 2rem;
  border-radius: 1rem;
  border: 1px solid black;
  text-align: center;
}
.mobile-email-container .mobile-email-body .mobile-btn-subscribe {
  margin-top: 0.75rem;
  border: none;
  border-radius: 1rem;
  padding: 0.35rem 1.5rem;
  background-color: #A3D183;
  color: white;
}/*# sourceMappingURL=InfoEmail.css.map */