@font-face {
  font-family: "poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.navbar-icon {
  height: 2.25rem;
}
.navbar-container-list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 860px) {
  .navbar-container-list {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0.75rem;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    left: 0;
    width: 100%;
    position: absolute;
    top: 3.5rem;
    text-align: end;
    border-bottom: 1px solid lightgrey;
  }
}

.navbar-list {
  font-weight: bold;
  list-style: none;
  font-size: 0.75rem;
  margin: 0px 0.75rem;
}

.navbar {
  padding: 0.75rem 5rem;
  display: flex;
  align-items: center;
}
@media (max-width: 860px) {
  .navbar {
    z-index: 2;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0.5rem 1.25rem;
    justify-content: space-between;
  }
}

.m-hamburger-menu {
  display: none;
}
@media (max-width: 860px) {
  .m-hamburger-menu {
    display: block;
  }
  .m-hamburger-menu img {
    cursor: pointer;
    height: 1.25rem;
    width: 1.75rem;
  }
}

.navbar-link {
  text-decoration: none;
  color: black;
}

.btn-language img {
  margin-left: 0.25rem;
  height: 1rem;
  cursor: pointer;
}/*# sourceMappingURL=App.css.map */