@font-face {
  font-family: "poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.about-banner-container {
  position: relative;
  height: 35rem;
  overflow: hidden;
}
@media (max-width: 992px) {
  .about-banner-container {
    overflow-x: hidden;
    height: 25rem;
  }
}

.about-page-container {
  padding: 0px 0rem;
}

.about-banner-img {
  position: absolute;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-banner-img.left {
  left: 0;
}
.about-banner-img.right {
  right: 0;
}

.about-banner-body {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.about-banner-body.left {
  align-items: flex-start;
  text-align: left;
  padding-left: 5rem;
  padding-right: 60%;
}
@media (max-width: 992px) {
  .about-banner-body.left {
    padding: 0 2.5rem;
  }
}
.about-banner-body.right {
  align-items: flex-end;
  text-align: right;
  padding-right: 5rem;
  padding-left: 60%;
}
@media (max-width: 992px) {
  .about-banner-body.right {
    padding: 0 2.5rem;
  }
}
.about-banner-body .about-banner-title {
  font-size: 2rem;
  margin: 0;
}
@media (max-width: 992px) {
  .about-banner-body .about-banner-title {
    font-size: 1rem;
  }
}
.about-banner-body .about-banner-text {
  font-size: 1rem;
  margin: 0;
}
@media (max-width: 992px) {
  .about-banner-body .about-banner-text {
    font-size: 0.68rem;
  }
}

.about-btn-learn-more {
  font-size: 0.85rem;
  cursor: pointer;
  margin-top: 0.5rem;
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 0.85rem 2.5rem;
  font-weight: bold;
  background-color: #a3d183;
}
@media (max-width: 992px) {
  .about-btn-learn-more {
    font-size: 0.65rem;
    padding: 0.45rem 1.25rem;
  }
}

.procedure-card-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.procedure-img {
  -o-object-fit: unset;
     object-fit: unset;
  width: 5rem;
}

.procedure-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.procedure-title {
  text-align: center;
}
@media (max-width: 992px) {
  .procedure-title {
    font-size: 1.15rem;
  }
}

.procedure-arrow {
  margin-top: 2.5rem;
  height: 0.75rem;
}
@media (max-width: 992px) {
  .procedure-arrow.left {
    transform: scaleX(-1);
  }
  .procedure-arrow.right {
    margin-left: -0.5rem;
  }
  .procedure-arrow.down {
    transform: rotate(90deg);
  }
}

.procedure-title {
  text-align: center;
}

.mobile-procedure-card-container {
  display: flex;
  flex-direction: column;
}

.mobile-procedure-card-top {
  display: flex;
  justify-content: center;
}
.mobile-procedure-card-top .mobile-producer-card {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}
.mobile-procedure-card-top .mobile-producer-card .mobile-producer-image {
  width: 4.5rem;
}
.mobile-procedure-card-top .mobile-producer-card p {
  text-align: center;
  font-size: 0.85rem;
}

.mobile-procedure-card-bottom {
  display: flex;
  justify-content: center;
}
.mobile-procedure-card-bottom .mobile-producer-card {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}
.mobile-procedure-card-bottom .mobile-producer-card .mobile-producer-image {
  width: 4.5rem;
}
.mobile-procedure-card-bottom .mobile-producer-card p {
  text-align: center;
  font-size: 0.85rem;
}

.procedure-arrow.down {
  margin-top: 0.5rem;
}/*# sourceMappingURL=About.css.map */