@use "./index.scss" as index;

.footer {
  overflow: hidden;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #CAA4CC;
  width: 100%;
}

.footer-phone {
  margin-top: .75rem;
  display: flex;
  align-items: center;
  gap: .5rem;

  p {
    margin: 0;
    font-size: 1rem;
    @include index.breakpoints(992px) {
      font-size: .85rem;
    }
  }
}

.footer-whatsapp {
  margin-top: .5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  p {
    margin: 0;
    font-size: 1rem;
    @include index.breakpoints(992px) {
      font-size: .85rem;
    }
  }
}

.footer h1 {
  text-align: center;
  margin: 0;
  font-size: 1.45rem;

  @include index.breakpoints(992px) {
    font-size: 1rem;
  }
}

.footer .footer-address {
  margin-top: .75rem;
  font-size: 1rem;
  text-align: center;
  width: 50%;
  margin-bottom: 0rem;

  @include index.breakpoints(992px) {
    width: 93.25%;
    font-size: .85rem;
  }
}