@use "./index.scss" as index;

.club-banner-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include index.breakpoints(992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.club-image-conatiner {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  img {
    height: 70%;
  }

  @include index.breakpoints(992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;

    img {
      height: 12.5rem;
    }
  }
}

.club-banner-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 6.5rem;

  .club-banner-title {
    margin-bottom: 0;
    text-align: left;
  }

  .club-banner-desc:nth-child(2) {
    margin-bottom: 1.5rem;
  }

  .club-banner-desc {
    margin: 0;
  }

  @include index.breakpoints(992px) {
    display: grid;
    place-items: center;
    padding: 0;

    .club-banner-title {
      margin-top: 0.75rem;
      margin-bottom: 0.25rem;
      text-align: center;
      font-size: 1.25rem;
    }

    .club-banner-desc:nth-child(2) {
      margin-bottom: 0;
    }

    .club-banner-desc {
      margin: 0;
      text-align: center;
      font-size: 1rem;
      max-width: 78%;
    }
  }
}

.club-programs-container {
  background-color: #d6ebf9;
  position: relative;
  padding-top: 1rem;

  @include index.breakpoints(992px) {
    margin-top: 2.5rem;
    padding-top: 0rem;
  }
}

.club-programs-card-container {
  margin-top: 1rem;
  padding-bottom: 5rem;
  display: grid;
  place-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 1.25rem;
  @include index.breakpoints(992px) {
    margin-top: 0;
    padding-top: 0rem;
    padding-left: 1.5rem;
    padding-bottom: 2rem;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-right: 1rem;
  }
}

.club-program-card-body {
  display: grid;
  place-items: center;
  background-color: white;
  padding: 1rem;
  height: 100%;

  @include index.breakpoints(992px) {
    padding: 0 2rem;
  }
}

.club-program-card-title {
  font-size: 1.25rem;
  margin: 0;

  @include index.breakpoints(992px) {
    font-size: 1rem;
    margin: 0;
  }
}

.club-program-card-desc {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  @include index.breakpoints(992px) {
    margin: 0 0 1.25rem 0;
  }
}

.club-program-card-img img {
  @include index.breakpoints(992px) {
    height: 4.25rem;
    margin-top: 2.75rem;
  }
}

.club-programs-container-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .club-program-icon-carousel {
    width: 4.5rem;
    height: 4rem;
  }

  .club-programs-container-title {
    font-size: 2.5rem;
    color: #44b3e4;
  }

  @include index.breakpoints(992px) {
    .club-programs-container-title {
      font-size: 1.5rem;
      color: #44b3e4;
    }

    .club-program-icon-carousel {
      width: 2.35rem;
      height: 2rem;
      margin-right: 0.35rem;
    }
  }
}

.club-why-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include index.breakpoints(992px) {
    flex-direction: column;
    justify-content: center;
  }
}

.club-why-banner-list {
  margin: 0;
}

.club-why-banner {
  margin-left: 10rem;
  @include index.breakpoints(992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
    padding-right: 1rem;
  }

  .club-why-banner-title {
    font-size: 2.35rem;
    @include index.breakpoints(992px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .club-why-banner-list {
    font-size: 1.35rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include index.breakpoints(992px) {
      font-size: 1.05rem;
    }
  }

  .club-why-banner-list::before {
    content: "";
    background-image: url("./img/star.png");
    background-size: cover;
    display: inline-block;
    margin-right: 1rem;
    background-position: center;
    width: 2rem;
    height: 2rem;

    @include index.breakpoints(992px) {
      box-sizing: border-box;
      width: 0.5rem;
      margin-right: 0.65rem;
      height: 0.55rem;
      background-size: cover;
      padding: 0.55rem;
    }
  }
}

.club-why-image {
  position: relative;
  height: 35rem;
  width: 40rem;

  @include index.breakpoints(992px) {
    height: auto;
    width: 101%;
    height: 15rem;
  }

  .club-why-image-kids {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 101%;
  }

  .club-why-image-bg {
    width: 100%;
    height: 100%;
  }
}

.club-benefit-top {
  width: 100%;
  height: 28rem;
  display: flex;
}

.club-benefit-info-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5rem;

  .club-benefit-info-container-title {
    margin: 0;
    line-height: 3rem;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 3rem;
  }

  li {
    position: relative;
    font-size: 1rem;
    list-style: none;
    margin-bottom: 0.65rem;
  }

  li::before {
    position: absolute;
    top: .275rem;
    left: -1.5rem;
    content: "";
    background-image: url("./img/check.png");
    background-size: cover;
    width: 1rem;
    height: 1rem;

    @include index.breakpoints(992px) {
      top: 16.5%;
    }
  }

  @include index.breakpoints(992px) {
    margin: 0;
    padding: 0 2rem;

    .club-benefit-info-container-title {
      padding-left: 1.25rem;
      font-size: 1.65rem;
      margin: 0;
    }
  }
}

.mobile-club-benefit-img-container {
  margin-top: -2.25rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.25rem;
  img {
    width: 100%;
  }
}

.mobile-join-social-club-title {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 0;
}

.mobile-social-btn-form-download {
  background-color: #44b3e4;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 1.5rem;
  font-size: 0.85rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.75rem;
}

.club-benefit-bottom {
  display: flex;
}

.mobile-social-club-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-social-club-media-title {
    font-size: 1.25rem;
  }

  .mobile-social-club-media-list {
    display: flex;
    justify-content: center;
  }

  .mobile-social-club-media-icon {
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    width: 8rem;
  }
}

.club-benefit-pic-1 {
  flex: 1;

  @include index.breakpoints(992px) {
    display: none;
  }

  img {
    box-sizing: border-box;
    padding: 0 1rem 0 1rem;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.club-benefit-pic-2 {
  flex: 1;

  @include index.breakpoints(992px) {
    display: none;
  }

  img {
    box-sizing: border-box;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.club-benefit-pic-3 {
  flex: 1;
  img {
    box-sizing: border-box;
    padding: 1rem;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.club-benefit-pic-4 {
  flex: 1;
  img {
    object-fit: cover;
    box-sizing: border-box;
    padding: 1rem 0;
    width: 100%;
    height: 100%;
  }
}

.social-club-join-card-container {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  gap: 2rem;
}

.social-club-join-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-club-join-card-top {
  position: relative;
  z-index: 1;
  font-size: 2.75rem;
  color: white;
  border-radius: 50%;
  width: 7.5rem;
  height: 7.5rem;
  display: grid;
  place-content: center;
  background-color: #ed5655;
}

.social-club-join-card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: -3.5rem;
  font-size: 1.25rem;
  background-color: #fff7df;
  padding: 2.25rem 1.15rem 0.5rem 1.15rem;
  border-radius: 1.5rem;
  width: 9rem;
  height: 17.5rem;
}

.social-club-join-button {
  cursor: pointer;
  background-color: #ed5655;
  border-radius: 2.5rem;
  padding: 0.35rem 0.75rem;
  font-size: 1rem;
  margin-top: 1.25rem;
  color: white;
}

.social-club-button-download-form {
  cursor: pointer;
  margin: 2.5rem 0rem;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.5rem 5rem;
  border: none;
  border-radius: 2.5rem;
  background-color: #44b3e4;
}

.mobile-social-card-container {
  display: flex;
  align-items: center;

  .mobile-social-card-container-number {
    margin-right: -3.75rem;
    background-color: #ed5655;
    width: 2.5rem;
    padding: 1.5rem;
    color: white;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: normal;
    z-index: 1;
  }

  .mobile-social-card-container-desc {
    text-align: center;
    border-radius: 1.5rem;
    width: 15rem;
    padding: 1.5rem 1.25rem;
    padding-left: 3.75rem;
    background-color: rgba(254, 201, 42, 0.15);
  }

  .mobile-btn-card-contact-us {
    margin: 0 auto;
    display: block;
    margin-top: 0.75rem;
    background-color: #ed5655;
    color: white;
    border: none;
    padding: 0.35rem 1.35rem;
    border-radius: 1.5rem;
  }
}

.mobile-social-card-container.left {
  margin-left: -2.5rem;
  .mobile-social-card-container-number {
    margin-left: -3.75rem;
    order: 2;
  }

  .mobile-social-card-container-desc {
    order: 1;
    padding-left: 0.85rem;
    padding-right: 4rem;
  }
}

.mobile-card-join-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}


.social-club-instagram {
  width: 100%;
}