@use "./index.scss" as index;

.team-banner-container {
  position: relative;
  width: 100%;
}

.team-banner {
  object-fit: cover;
  width: 100%;
  margin-bottom: 2.5rem;

  @include index.breakpoints(992px) {
    display: none;
  }
}

.team-banner-title {
  position: absolute;
  top: 30%;
  left: 45%;
  color: white;

  @include index.breakpoints(992px) {
    text-align: center;
    position: initial;
    color: black;
    font-size: 1.35rem;
  }
}

.team-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-left: 5.5rem;

  @include index.breakpoints(992px) {
    padding-left: 0.5rem;
    gap: 0.5rem;
  }
}

.team-filter-tag {
  border: 1px solid #a3d183;
  color: #a3d183;
  font-weight: bold;
  padding: 0.5rem 1.25rem;
  border-radius: 100px;
  cursor: pointer;

  &:hover,
  &.active {
    color: white;
    background-color: #a3d183;
  }

  @include index.breakpoints(992px) {
    padding: 0.5rem 1.25rem;
    font-size: 0.65rem;
  }
}

.team-info-img-container {
  text-align: center;
  position: relative;
  margin-bottom: -2.5rem;
  height: 10.5rem;

  @include index.breakpoints(992px) {
    height: 7.5rem;
  }
}

.team-info-placeholder {
  height: 100%;
  position: absolute;
}

.team-info-img {
  height: 100%;
  margin-top: 2.85rem;
  background-image: url("./img//circle.png");
  background-size: cover;
  background-position: center;

  @include index.breakpoints(992px) {
    height: 5.5rem;
    margin-top: 2.15rem;
  }
}

.team-info-container {
  padding: 5rem;
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(3, 1fr);

  @include index.breakpoints(992px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.25rem;
    gap: 1rem;
  }
}

.team-info-card-title,
.team-info-card-desc {
  font-size: 1rem;
  text-align: center;
  margin-top: 0rem;

  @include index.breakpoints(992px) {
    font-size: .65rem;
  }
}

.team-info-card-desc {
  padding: 0 0.25rem;
}

.team-info-card-title {
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;

  @include index.breakpoints(992px) {
    margin-top: 2.25rem;
  }
}

.team-info-card-detail {
  padding: 5.5rem .25rem 0rem .25rem;
  height: 10rem;
  background-color: #f5f5f5;
  @include index.breakpoints(992px) {
    height: 8rem;
    padding: 1.25rem .75rem 1rem .75rem;
  }
}
