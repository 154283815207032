@use "./index.scss" as index;

.contact-banner-info-container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 13.5rem;
  padding-top: 5rem;
  display: flex;
  gap: 3.5rem;

  @include index.breakpoints(992px) {
    flex-direction: column;
    padding: 0 3.5rem;
    padding-top: 3rem;
    gap: 2rem;
  }

  .contact-banner-info-icon {
    font-size: 3.5rem;

    @include index.breakpoints(992px) {
      font-size: 2rem;
    }
  }

  .contact-banner-info-card-title {
    margin: 0;
    font-size: 1.5rem;

    @include index.breakpoints(992px) {
      font-size: 1rem;
    }
  }

  .contact-banner-info-card-desc {
    text-align: center;
    margin: 0;
    font-size: 1rem;
    font-weight: 200;

    @include index.breakpoints(992px) {
      font-size: 0.75rem;
    }
  }

  .contact-banner-info-card-notes {
    text-align: center;
    margin: 0;
    font-size: 1rem;
    @include index.breakpoints(992px) {
      font-size: 0.75rem;
    }
  }

  .contact-banner-info-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include index.breakpoints(992px) {
        gap: .35rem;
      }
  }
}

.contact-banner-container {
  position: relative;
  width: 100%;
  height: 30rem;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 4rem;
    margin: 0;

    @include index.breakpoints(992px) {
      font-size: 2rem;
    }
  }

  p {
    margin: 0;
    margin-top: -1rem;
    font-size: 2.05rem;

    @include index.breakpoints(992px) {
      margin-top: -0.5rem;
      font-size: 1.05rem;
    }
  }
}

.contact-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.contact-info-email-container {
    padding: 0 6.5rem;
    padding-top: 5rem;
    display: flex;
    background-color: #EEEEEE;
    margin-top: 5rem;
    height: 80vh;

    @include index.breakpoints(992px) {
        padding: 0;
        flex-direction: column;
        height: 100%;
        padding-bottom: 5rem;
    }

    .contact-info-email-container-left {
        padding-top: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 2.5;
        border-right: 2px solid #D9D9D9;
    }

    .contact-info-email-container-right {
        flex: 1;
        padding: 0 1.5rem;
        padding-top: 2.5rem;

        @include index.breakpoints(992px) {
            padding: 0 2.5rem;
            padding-top: 4.5rem;
        }
    }
}

.contact-info-form-title {
    text-align: center;

    h1 {
        font-size: 2rem;

        @include index.breakpoints(992px) {
            font-size: 1.5rem;
        }
    }
}

.contact-info-working-hours {
    display: flex;
    gap: 1.25rem;

    .contact-info-working-hours-sections, .contact-info-working-day-sections{
        color: #818080;
    }
}

.contact-info-email-container-right-desc {
    margin: 1rem 0;
    @include index.breakpoints(992px) {
        margin-top: .35rem;
    }
}

.contact-info-form-container {
    margin-top: 1.5rem;
    box-sizing: border-box;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    padding: 0 5rem;

    @include index.breakpoints(992px) {
        padding: 0 2.5rem;
    }

    div {
        display: flex;
        gap: 1rem;

        @include index.breakpoints(992px) {
            flex-direction: column;
        }
    }

    .btn-submit-form {
        background-color: #717171;
        color: white;
        padding: .5rem;
        cursor: pointer;
        border: none;
    }

    input {
        padding: .65rem;
        border: none;
    }

    textarea {
        border: none;
        resize: none;
        height: 6.5rem;
        padding: .65rem;
    }

    input::placeholder, textarea::placeholder {
        color: black;
    }
}