.whatsapp-plugin-button {
  z-index: 2;
  background-color: #a3d183;
  padding: 0.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: fixed;
  bottom: 5rem;
  color: white;
  font-weight: bold;
  right: 1.5rem;
  box-shadow: 0px 0px 32px 0px #cacaca59;
  border: 1px solid #cacaca59;
}

.whatsapp-plugin-button > .whatsapp-plugin-icon {
  font-size: 2.5rem;
}
