@font-face {
  font-family: "poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  color: #272727;
}

* {
  font-family: "Poppins", sans-serif;
}

.footer {
  overflow: hidden;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #CAA4CC;
  width: 100%;
}

.footer-phone {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.footer-phone p {
  margin: 0;
  font-size: 1rem;
}
@media (max-width: 992px) {
  .footer-phone p {
    font-size: 0.85rem;
  }
}

.footer-whatsapp {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.footer-whatsapp p {
  margin: 0;
  font-size: 1rem;
}
@media (max-width: 992px) {
  .footer-whatsapp p {
    font-size: 0.85rem;
  }
}

.footer h1 {
  text-align: center;
  margin: 0;
  font-size: 1.45rem;
}
@media (max-width: 992px) {
  .footer h1 {
    font-size: 1rem;
  }
}

.footer .footer-address {
  margin-top: 0.75rem;
  font-size: 1rem;
  text-align: center;
  width: 50%;
  margin-bottom: 0rem;
}
@media (max-width: 992px) {
  .footer .footer-address {
    width: 93.25%;
    font-size: 0.85rem;
  }
}/*# sourceMappingURL=Footer.css.map */