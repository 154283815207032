.mobile-info-email-container {
    margin: 1.25rem 0;
    padding: 0 1.5rem;
    align-items: center;
    gap: 1rem;

    .mobile-info-email-top {
        color: white;
        background-color: #AA7FC9;

        .mobile-call-us {
            border-bottom: 1px solid white;
            padding: .75rem 1rem;
            button {
                margin-bottom: .5rem;
                font-size: .6rem;
                background-color: white;
                border: none;
                border-radius: 1rem;
                color: black;
                font-weight: bold;
                width: 9.5rem;
                padding: .35rem .85rem;
            }
        }

        .mobile-info-location {
            padding: 1.25rem 1rem;
            border-bottom: 1px solid white;
            button {
                font-size: .6rem;
                background-color: white;
                border: none;
                border-radius: 1rem;
                color: black;
                font-weight: bold;
                padding: .25rem 1rem;
            }
        }

        .mobile-opening-hours {
            padding: 1.25rem 1rem;
            padding-bottom: 2rem;
            .mobile-opening-hours-section {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid white;
            }
        }

        .mobile-title-section {
            margin-left: .5rem;
            font-weight: normal;
            font-size: 0.85rem;
        }
        
        p {
            font-size: .85rem;
        }
    }

    .mobile-info-email-bottom {
        text-align: center;
        .mobile-title-section {
            font-size: 1.5rem;
        }
    }
}

.mobile-icon-social-media {
    padding: .5rem;
    margin-right: .5rem;
    font-size: 1.25rem !important;
    color: white;
    background-color: black;
    border-radius: 2rem;
}

.mobile-email-container {
    margin-bottom: 1rem;
    position: relative;

    img {
        width: 100%;
    }

    .mobile-email-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 1.5rem;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        h1 {
            text-align: center;
            font-size: 1rem;
        }

        .email {
            padding: .3rem 2rem;
            border-radius: 1rem;
            border: 1px solid black;
            text-align: center;
        }

        .mobile-btn-subscribe {
            margin-top: .75rem;
            border: none;
            border-radius: 1rem;
            padding: .35rem 1.5rem;
            background-color: #A3D183;
            color: white;
        }
    }
}